import './mini-gallery.styles.scss';


const MiniGallery = (data) => {
  return (
    <div className='mini-gallery-container'>
    {
      data.data.map((item, idx)=>{
        return (
            <img className='mini-gallery-img max-width' src={item.imgSrc} alt={item.imgAlt} key={`${item.imgAlt} ${idx}`}/>
        )
      })
    }
    </div>
  )
}

export default MiniGallery;
