import './star-rating.styles.scss';
import { TiStarFullOutline } from "react-icons/ti";

const StarRating = () => {
  return (
    <div className='star-review-container'>
      <input type="radio" name={'rate'} id='rate-1' value={5}/>
      <label for='rate-1' ><TiStarFullOutline /></label>
      <input type="radio" name={'rate'} id='rate-2' value={4}/>
      <label for='rate-2' ><TiStarFullOutline /></label>
      <input type="radio" name={'rate'} id='rate-3' value={3}/>
      <label for='rate-3' ><TiStarFullOutline /></label>
      <input type="radio" name={'rate'} id='rate-4' value={2}/>
      <label for='rate-4' ><TiStarFullOutline /></label>
      <input type="radio" name={'rate'} id='rate-5' value={1}/>
      <label for='rate-5' ><TiStarFullOutline /></label>
    </div>
  )
}

export default StarRating;
