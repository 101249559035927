import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PreviewAdsProvider } from './context/preview-Ads-context';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";
import { PreviewAdsFiltersProvider } from './context/ad-preview-filters-context';

ReactGA.initialize("G-GT2FJYJBT2");

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PreviewAdsProvider>
      <PreviewAdsFiltersProvider>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </PreviewAdsFiltersProvider>
    </PreviewAdsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
