import './service-preview.styles.scss';

const ServicePreview = (props) => {
    const {Imgs} = props;
  
  
    return (
      <div className="service-preview-container">
      {Imgs.map((item ,idx )=> {
        return (
          <div className="img-container" key={idx}>
            <h3 className="service-preview-name">{item.title}</h3>
            <img 
              src={item.imgSrc} 
              alt={item.alt} 
              className="img"
              />
          </div>
        );
      })}
      </div>
    )
  }

export default ServicePreview;
