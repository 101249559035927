import './company-info-card.styles.scss'


const CompanyInfoCard = (props) => {

    const {icon, header, text} = props

    return(
        <div className="company-info-card-container">
            <div className="company-info-card-icon-container">
                {icon}
            </div>
            <div className="company-info-card-text-container">
                <h3 className="company-info-card-text-headline">{header}</h3>
                <p className="company-info-card-text">
                    {text}
                </p>
            </div>
        </div>
    )
}

export default CompanyInfoCard;