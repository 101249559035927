import { Routes, Route} from 'react-router-dom';
import './App.scss';
import HomePage from './home-page';
import AdvertPage from './advert-page';
import Navbar from './components/navbar/navbar.component';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';


import { KyanWyssAdInfo, TaiWyssAdInfo, CairHandleyAdInfo, TarynDeKerkAdInfo, CandiceUlyateAdInfo, NicoleVanSensieAdInfo, TohierahKarriemAdInfo, JudithFungisaniAdInfo,YolanderPintuAdInfo,MilkaCoxAdInfo, DoniraPhiriAdInfo, SarahMaungaAdInfo,XolaNdumndumAdInfo,GizzelleBeyersAdInfo,CandiceBennettAdInfo,MelanieHendricksAdInfo,NatashaPAdInfo,JosehineBerylDanielsAdInfo,ThandieBeautyAdInfo,TeeTishaAdInfo,NeliaNBAdInfo,LoganMentoAdInfo,AmaliaFeroAdInfo} from './assests/data/advert data/advert-data.component';


function App() {
  useEffect(() => {
    const tagManagerArgs = {
        gtmId: 'GTM-PSCLQG7B'
    };
    TagManager.initialize(tagManagerArgs);
}, []);

  return (
    <>
      <Routes>
            <Route element={<Navbar/>}>
            <Route index element={<HomePage/>}></Route>
            </Route>
            {/* <Route element={<AdNavbar/>}> */}
            <Route path='/freelancer' element={<AdvertPage pageInfo={KyanWyssAdInfo}/>}></Route>
            <Route path='/business' element={<AdvertPage pageInfo={TaiWyssAdInfo}/>}></Route>
            <Route path={CairHandleyAdInfo.pageHref} element={<AdvertPage pageInfo={CairHandleyAdInfo}/>}></Route>
            <Route path={TarynDeKerkAdInfo.pageHref} element={<AdvertPage pageInfo={TarynDeKerkAdInfo}/>}></Route>
            <Route path={CandiceUlyateAdInfo.pageHref} element={<AdvertPage pageInfo={CandiceUlyateAdInfo}/>}></Route>
            <Route path={NicoleVanSensieAdInfo.pageHref} element={<AdvertPage pageInfo={NicoleVanSensieAdInfo}/>}></Route>
            <Route path={TohierahKarriemAdInfo.pageHref} element={<AdvertPage pageInfo={TohierahKarriemAdInfo}/>}></Route>
            <Route path={JudithFungisaniAdInfo.pageHref} element={<AdvertPage pageInfo={JudithFungisaniAdInfo}/>}></Route>
            <Route path={YolanderPintuAdInfo.pageHref} element={<AdvertPage pageInfo={YolanderPintuAdInfo}/>}></Route>
            <Route path={MilkaCoxAdInfo.pageHref} element={<AdvertPage pageInfo={MilkaCoxAdInfo}/>}></Route>
            <Route path={SarahMaungaAdInfo.pageHref} element={<AdvertPage pageInfo={SarahMaungaAdInfo}/>}></Route>
            <Route path={DoniraPhiriAdInfo.pageHref} element={<AdvertPage pageInfo={DoniraPhiriAdInfo}/>}></Route>
            <Route path={XolaNdumndumAdInfo.pageHref} element={<AdvertPage pageInfo={XolaNdumndumAdInfo}/>}></Route>
            <Route path={GizzelleBeyersAdInfo.pageHref} element={<AdvertPage pageInfo={GizzelleBeyersAdInfo}/>}></Route>
            <Route path={CandiceBennettAdInfo.pageHref} element={<AdvertPage pageInfo={CandiceBennettAdInfo}/>}></Route>
            <Route path={MelanieHendricksAdInfo.pageHref} element={<AdvertPage pageInfo={MelanieHendricksAdInfo}/>}></Route>
            <Route path={NatashaPAdInfo.pageHref} element={<AdvertPage pageInfo={NatashaPAdInfo}/>}></Route>
            <Route path={JosehineBerylDanielsAdInfo.pageHref} element={<AdvertPage pageInfo={JosehineBerylDanielsAdInfo}/>}></Route>
            <Route path={ThandieBeautyAdInfo.pageHref} element={<AdvertPage pageInfo={ThandieBeautyAdInfo}/>}></Route>
            <Route path={TeeTishaAdInfo.pageHref} element={<AdvertPage pageInfo={TeeTishaAdInfo}/>}></Route>
            <Route path={NeliaNBAdInfo.pageHref} element={<AdvertPage pageInfo={NeliaNBAdInfo}/>}></Route>
            <Route path={LoganMentoAdInfo.pageHref} element={<AdvertPage pageInfo={LoganMentoAdInfo}/>}></Route>
            <Route path={AmaliaFeroAdInfo.pageHref} element={<AdvertPage pageInfo={AmaliaFeroAdInfo}/>}></Route>
            {/* </Route> */}

            {/* <Route element={<Navbar/>}>
              
              <Route path='business' element={<AdvertPage pageInfo={TaiWyssAdInfo}/>}></Route>
              <Route path='freelancer' element={<AdvertPage pageInfo={KyanWyssAdInfo}/>}></Route>
            </Route> */}
      </Routes>
    </>
  );
}

export default App;
