import './advert-section.styles.scss';

import Advert from '../../components/advert/advert.component';
import AdSidebar from '../../components/ad sidebar/ad-sidebar.component';
import Button from '../../components/button/button.component';

import PriceList from '../../components/price list/price-list.component';
import { Link } from 'react-router-dom';

const AdvertSection = (pageInfo) => {
  const pageData = pageInfo.pageInfo

  const priceListRender = (pageData) => {
    if (!pageData.pageInfo.priceList) {
      return
    }else return (
      pageData.pageInfo.priceList.map((item, idx) => <PriceList key={idx} data={item}/> )
    )
  }


  return (
    <>
      <div className='advert-section-container'>
        <Advert data={pageData}/>
        <AdSidebar data={pageData}/>
      </div>
      <div className="center-container">
      <Button>
        <Link to='/#discover' className='back-link bold'>Back to catalog</Link>
      </Button>
      </div>
      <div className="advert-other-info-container">
        <div className={pageData.pageInfo.priceList ? "price-list-section-container" : 'display-none'}>
          <h2 className="price-list-section-header">Our Price List</h2>
          {
            priceListRender(pageData)
          }
        </div>
      </div>
    </>
  )
}

export default AdvertSection;
