import img1 from "../service preview imgs/Make Up Artist service img.jpg";
import img2 from "../service preview imgs/Hair stylist service img.jpg";
import img3 from "../service preview imgs/Skin Theripists service img.png";
import img4 from "../service preview imgs/Nail Technician service img.png";
import img5 from "../service preview imgs/Hair Removal.png";
import img6 from "../service preview imgs/Massage Theripsit service img.jpg";



export const Imgs = [
    {
        alt: "Make Up Artist service img",
        imgSrc:img1 ,
        title: "Eyelash Technicians",
        number: 1
    },
    {
        alt: "Hair stylist service img",
        imgSrc:img2 ,
        title: "Hair Stylist",
        number: 2
    },
    {
        alt: "Skin Theripists service img",
        imgSrc:img3 ,
        title: "Skincare",
        number: 3
    },
    {
        alt: "image 4",
        imgSrc:img4 ,
        title: "Nail Technician",
        number: 4
    },
    {
        alt: "Beauty Consultant service img.jpg",
        imgSrc:img5 ,
        title: "Hair Removal",
        number: 5
    },
    {
        alt: "Massage Theripsit service img",
        imgSrc:img6 ,
        title: "Massage Therapist",
        number: 6
    },
]