import HeroSection from './sections/hero section/heroSection.component'
import ServicePreviewSection from './sections/service preview section/service-preview-section.component'
import AboutSection from './sections/about section/about-section.component'
import FAQSection from './sections/faq section/faq-section.component'
import ContactFormSection from './sections/contact-form-section/contact-form-section.component'
import Footer from './components/footer/footer.component'

import AdvertSearchSection from './sections/advert search section/advert-search-section.component'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'


const HomePage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }, []);

  return (
    <div>
      <>
        <Helmet>
          <title>Find Top Beauty & Wellness Services in Cape Town | Back2Beauty</title>
          <meta name="description" content="Discover top beauty & wellness services across Cape Town with Back2Beauty. Find and book trusted local experts or advertise your own salon or service today!" />
          <link rel="canonical" href="/"/>
        </Helmet>
        <HeroSection/>
        <ServicePreviewSection/>
        <AdvertSearchSection />
        <AboutSection />
        <ContactFormSection/>
        <FAQSection/>
        <Footer/>
      </>
    </div>
  )
}

export default HomePage
