import './ad-sidebar.styles.scss';

import InstaIcon from '../../assests/svg icons/insta-icon.svg'
import TwitterIcon from '../../assests/svg icons/twitter-icon.svg'
import FaceBookIcon from '../../assests/svg icons/facebook-icon.svg'
import GmailIcon from '../../assests/svg icons/gmail-icon.svg'

import { FaPhoneAlt } from "react-icons/fa";
import Button from '../button/button.component';

const businessHrsMap = (workHours, idx) => {
  return (
      <div className="sidebar-business-hours-container" key={idx}>{workHours.map((item, idx) => 
        <div className="sidebar-business-hours-info" key={idx}>
          <span className="day">{item.day}</span>
          <span className="hours">{item.hours}</span>
        </div>)}
      </div>
  )
}





const AdSidebar = (data) => {

  const adInfo = data.data.pageInfo

  const about = adInfo.about;
  const number = adInfo.number;
  const email = adInfo.email;
  const workHours = adInfo.workHours;
  const instaHref = adInfo.instaHref;
  const twitterHref = adInfo.twitterHref;
  const facebookHref = adInfo.facebookHref;
  const bookHref = adInfo.bookHref;
  const callOuts = adInfo.callOuts;


  return (
    <div className='ad-sidebar-container'>
      <h2 className="sidebar-header">About Us</h2>
      <p className="sidebar-text">
        {about}
      </p>

      {/* SIDEBAR CONTACT INFO */}

      <h2 className="sidebar-header">Contact & Business hours</h2>
      <div className="sidebar-contact-info-container">
        <p className="sidebar-text">
          <FaPhoneAlt className='sidebar-icon'/>
          {number}
        </p>
        <p className={email ? "sidebar-text" : 'display-none'}>
          <img alt='' src={GmailIcon} className='sidebar-icon'/>
          {email}
        </p>
      </div>

      {/* BUISNESS HOURS */}

        {
          businessHrsMap(workHours)
        }

      <h2 className="sidebar-header">Additional Information</h2>
      <p className="sidebar-text">
        {callOuts}
      </p>

      {/* SIDEBAR SOCIALS */}

      <h2 className={!instaHref & !twitterHref & !facebookHref ? "display-none": "sidebar-header"}>Socials</h2>
      <div className="sidebar-socials-container">
        <a className={!instaHref ? "display-none" : ''} href={instaHref}>
        <img alt='' src={InstaIcon}  className='sidebar-icon insta-icon'/>
        </a>
        <a className={!twitterHref ? "display-none" : ''} href={twitterHref}>
        <img alt='' src={TwitterIcon}  className='sidebar-icon twitter-icon'/>
        </a>
        <a className={!facebookHref ? "display-none" : ''} href={facebookHref}>
        <img  alt='' src={FaceBookIcon} className='sidebar-icon facebook-icon'/>
        </a>
      </div>
      <div className="sidebar-btn-container">
        <Button className='sidebar-btn' buttonSize='btn--large' href={bookHref}>BOOK</Button>
      </div>
      <span className={adInfo.priceList ? 'display-none' : 'nb-text'}>N.B. Contact for Prices</span>
    </div>
  )
}

export default AdSidebar;
