import './ad-preview-card.styles.scss';

// import { IoStar,IoStarOutline,IoStarHalf } from "react-icons/io5";
import { IoStar } from "react-icons/io5";
import { IoMdMale, IoMdFemale } from "react-icons/io";



const serviceCardMap = (serviceArr, idx) => {
  
  return (
    <div className="service-cards-container">{serviceArr.map
      (service =><div className="service-card" key={`service card ${idx}`}> <span className="service-card-text" key={`service card text ${idx}`}>{service}</span></div>)}
    </div>
  )
}

const genderDisplay = (gender) =>{
  if (gender === 1) {
    return <IoMdMale className='gender-icon blue' />
  }else if (gender === 2) {
    return  <IoMdFemale className='gender-icon pink' />
  } else return
}

const imageDisplay = (companyType, imgSrc , imgAlt) => {
  if (companyType === 'Free lancer') {
    return <img src={imgSrc} alt={imgAlt}className='freelance-img'></img>
  }else return <img src={imgSrc} alt={imgAlt}className='company-img'></img>
}


const AdPreviewCard = (adPreviewInfo) => {

  const name = adPreviewInfo.data.Name;
  const imgSrc = adPreviewInfo.data.imgSrc;
  const Town = adPreviewInfo.data.Town;
  const about = adPreviewInfo.data.about;
  const Services = adPreviewInfo.data.Services;
  const companyType = adPreviewInfo.data.companyType;
  const imgAlt = adPreviewInfo.data.imgAlt;
  const pageHref = adPreviewInfo.data.pageHref;
  const gender = adPreviewInfo.data.gender;
  const reviewNo = adPreviewInfo.data.ReviewNo;
  const starNo = adPreviewInfo.data.starNo;
  // const rating = adPreviewInfo.data.rating;

  return (
    <a className='advert-card-link-container'  rel="noreferrer" href={pageHref}>
    <div className='ad-preview-card-container' >
      <div className="profile-img-container">
      {
        imageDisplay(companyType, imgSrc, imgAlt)
      }  
      </div>
      <span className="profile-name bold">{name}</span>
      <div className={ gender === 1 ? "rating-container blue" : gender === 2 ? "rating-container pink" : "rating-container green"}>
        <div className="review-star-container">
          <span className='star-no'>{starNo}</span>
          <IoStar className='star'/>
          <span className='review-no'>({reviewNo})</span>
        </div>
      </div>
      
      {
        serviceCardMap(Services)
      }

      <div className="profile-summary-container">
        <p className='profile-summary'>
          <span className='bold'>{Town}</span>
          {about}
        </p>
      </div>
      <div className="ad-preview-card-footer">
        <div></div>
        {/* <Button className='btn' href={pageHref}>See Details</Button> */}
        <span>{companyType}</span>
      </div>
      <div className="gender-container">
        {genderDisplay(gender)}
      </div>
    </div>
    </a>

  )
}

export default AdPreviewCard;
