import './advert-search-section.styles.scss';

import { useEffect, useState} from 'react';

import AdPreviewCard from '../../components/ad preview card/ad-preview-card.component';
import { AdPreviewsInfoList } from '../../assests/data/ad preview data/ad-preview-data.component';
import { IoSearch } from "react-icons/io5";
import Button from '../../components/button/button.component';

import { useSelectedLocation, useSelectedLocationUpdate , useSelectedService , useSelectedServiceUpdate, useSearchQuery, useSearchQueryUpdate, useLocationOption , useLocationOptionUpdate,useServiceOption,useServiceOptionUpdate } from '../../context/ad-preview-filters-context';



const AdvertSearchSection = () => {

    // const [selectedLocation, setSelectedLocation] = useState('')
    const selectedLocation = useSelectedLocation()
    const setSelectedLocation = useSelectedLocationUpdate()

    // const [selectedService, setSelectedService] = useState('')
    const selectedService = useSelectedService()
    const setSelectedService = useSelectedServiceUpdate()

    // const [searchQuery, setSearchQuery] = useState('')
    const searchQuery = useSearchQuery()
    const setSearchQuery = useSearchQueryUpdate()



    const [locationSearchQuery, setLocationSearchQuery] = useState('')
    const [serviceSearchQuery, setServiceSearchQuery] = useState('')
    // const [searchOptionValue, setSearchOptionValue] = useState('Any Location')
    const searchOptionValue = useLocationOption()
    const setSearchOptionValue = useLocationOptionUpdate()

    // const [serviceOptionValue, setServiceOptionValue] = useState('Any Service')
    const serviceOptionValue = useServiceOption()
    const setServiceOptionValue = useServiceOptionUpdate()

    const [serviceState, setServiceState] = useState('')
    const [locationState, setLocationState] = useState('')
    const [spliceNo, setSpliceNo] = useState(4)



    const LOCATIONS_ARR = ['Any Location','Century City','City Bowl','Claremont','Crawford','Kensington','Lansdowne','Macassar','Mitchells Plain','Sea Point','Tulbagh','Wynberg']
    const SERVICES_ARR = ['Any Service', 'Eyelash Technician', 'Hair Removal', 'Hairstylist', 'Massage Therapist', 'Nail Technician', 'Skincare']

    let advertListLength = 0


    
    //    SEARCH LIST OF OBJECTS
    
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredAds = AdPreviewsInfoList.filter((ad) => 
        ad.Name.toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase())
        !== -1 );

    const filteredLocations = LOCATIONS_ARR.filter((location) => 
        location.toLocaleLowerCase().indexOf(locationSearchQuery.toLocaleLowerCase()) !== -1 );

    const filteredServices = SERVICES_ARR.filter((service) => 
        service.toLocaleLowerCase().indexOf(serviceSearchQuery.toLocaleLowerCase()) !== -1 );

    
    
    //    FILTER LIST OF OBJECTS
    
  

    const filteredData = (adverts, selectedLocation, selectedService,query) => {
        let filteredAdverts = adverts;

        // filtering search query
        
        //filtering  selected location
        
        if(query){
            filteredAdverts = filteredAds
        }
        if (selectedLocation) {
            filteredAdverts = filteredAdverts.filter((ad) => ad.Town.toLocaleLowerCase().indexOf(selectedLocation.toLocaleLowerCase())!== -1)
        }
        
        if (selectedService) {
            filteredAdverts = filteredAdverts.filter((ad) => ad.Services.includes(selectedService))
        }

    
        return filteredAdverts.map((ad, idx) => {
            advertListLength ++
            return <AdPreviewCard data={ad} key={idx}/>
        })
    }

    const adPreviewFilteredList = filteredData(AdPreviewsInfoList,selectedLocation,selectedService,searchQuery);

    const toggleServiceState = () => {
        if (serviceState === 'active'){
            setServiceState('');
            setServiceSearchQuery('');
        }else 
        setServiceState('active');
        setServiceSearchQuery('');
        setLocationState('');
        setLocationSearchQuery('');
    }
    const toggleLocationState = () => {
        if (locationState === 'active'){
            setLocationState('');
            setLocationSearchQuery('');
        }else 
        setLocationState('active');
        setLocationSearchQuery('');
        setServiceState('');
        setServiceSearchQuery('');
    }

    const handleLocationSearch = (event) => {
        setLocationSearchQuery(event.target.value);
    };
    const handleServiceSearch = (event) => {
        setServiceSearchQuery(event.target.value);
    };

    const onSearchOptionValueChange = (location) => {
        if (location === 'Any Location'){
            setSearchOptionValue(location)
            setSelectedLocation('')
            toggleLocationState();  

        }else {

            setSearchOptionValue(location);
            setSelectedLocation (location);
            toggleLocationState();  
        }
        return 
    }
    const onServiceOptionValueChange = (service) => {
        if (service === 'Any Service'){
            setServiceOptionValue(service)
            setSelectedService('')
            toggleServiceState();  

        }else {

            setServiceOptionValue(service);
            setSelectedService (service);
            toggleServiceState();  
        }
        return 
    }

    const viewMoreViewLess = (e) => {
        if (spliceNo < advertListLength){
            return (
                <Button 
                onClick={() => buttonOnclick()}
                >
                    View More
                </Button>
            )
        }else return (
            <Button 
            onClick={() => buttonOnclick()}
            href='#discover'
            >
                View Less
            </Button>
        )
    }

    const buttonOnclick = () => {
        if (spliceNo < advertListLength) {
           return setSpliceNo(spliceNo + 4 )
        }else  if (spliceNo >= advertListLength){
            return setSpliceNo(4)
        }
    }



    
    





    return (
    <section id='discover'>

        <div className='advert-search-section-container'>
            

            <div className="inputs-wrapper">
            <div className="inputs-text-container">
                <h2 className="inputs-text-header">Discover</h2>
                <span className="inputs-text-subheader">Find the the best solution to any of you Beauty and Wellness needs.</span>
            </div>
            <div className="inputs-container">

                {/* // SELECT LOCATION */}

                <div className={`select-box ${locationState}`}>
                    <div className="first-input select-option">
                        <input type="text"  id='soValue' readOnly onClick={toggleLocationState}  value={searchOptionValue} name='' />
                    </div>
                    <div className="content">
                        <div className="search-location">
                            <input type="text"  onChange={handleLocationSearch} placeholder='Search Location'  value={locationSearchQuery} />
                        </div>
                        <ul className='options'>
                            {
                                filteredLocations.map((location , idx) => <li className={location === searchOptionValue ? 'active' : ''}onClick={()=> onSearchOptionValueChange(location)} key={`location ${idx}`}>{location}</li>)
                            }
                        </ul>
                    </div>
                </div>

                    {/* // SELECT SERVICE */}

                <div className={`select-box ${serviceState}`}>
                    <div className=" second-input select-option">
                        <input type="text"  id='soValue' readOnly onClick={toggleServiceState}  value={serviceOptionValue} name='' />
                    </div>
                    <div className="content">
                        <div className="search-service">
                            <input type="text"  onChange={handleServiceSearch} placeholder='Search Service'  value={serviceSearchQuery} />
                        </div>
                        <ul className='options'>
                            {
                                filteredServices.map((service , idx) => <li className={service === serviceOptionValue ? 'active' : ''}onClick={()=> onServiceOptionValueChange(service)} key={` ${idx}`}>{service}</li>)
                            }
                        </ul>
                    </div>
                </div>

                    {/* //FILTER SEARCH  */}

                <div className="search-container">

                    <input
                    type="text"
                    placeholder="Search Advert name..."
                    className='search'
                    value={searchQuery}
                    onChange={handleSearch}
                    />
                    <div className="search-icon-container">
                    <IoSearch className='search-icon'/>
                    </div>
                </div>
            </div>
            </div>
            <div className="advert-previews-container">
                {
                    adPreviewFilteredList.splice(0 , spliceNo)
                }
            </div>
            <div className={advertListLength <= 4 ? 'display-none' : ''}>

                {viewMoreViewLess()}

            </div>
            
            </div>
    </section>
    )
}


export default AdvertSearchSection;


