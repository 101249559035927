import './price-list.styles.scss';


const PriceList = (data) => {
  const priceObj = data.data


  return (
        <div className="price-lists-container">
          <h3 className="price-lists-header">{priceObj.priceHeader}</h3>
          <div className="grid-container">
              {
                priceObj.priceSubSections.map((item, idx) => {
                  return (  
                    <div className="price-list-container" key={idx}>
                      <h4 className="price-list-header">{item.priceSubSectionHeader}</h4>
                      {
                        item.priceSubSectionItems.map((subItem, idx)=>{
                          return(
                          <div className="price-list" key={idx}>
                            <span className="price-list-subheader">{subItem.item}</span>
                            <span className="price">{`R ${subItem.cost}`}</span>
                          </div>
                          )
                      })
                      }
                    </div>
                  )
                })
              }



            {/* <div className="price-list-container">
              <h4 className="price-list-header">FACIALS</h4>
              <div className="price-list">
                <span className="price-list-subheader">Customised Seasonal Facial</span>
                <span className="price">R300</span>
              </div>
              <div className="price-list">
                <span className="price-list-subheader">Customised Seasonal Facial</span>
                <span className="price">R300</span>
              </div>
            </div>
            <div className="price-list-container">
              <h4 className="price-list-header">FACIALS</h4>
              <div className="price-list">
                <span className="price-list-subheader">Customised Seasonal Facial</span>
                <span className="price">R300</span>
              </div>
              <div className="price-list">
                <span className="price-list-subheader">Customised Seasonal Facial</span>
                <span className="price">R300</span>
              </div>
            </div> */}
          </div>  
        </div>
  )
}

export default PriceList;
