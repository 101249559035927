import { createContext, useState, useContext, Children, useEffect } from "react";
import { AdPreviewsInfoList } from "../assests/data/ad preview data/ad-preview-data.component";


    // CREATE CONTEXT

export const SelectedLocationContext = createContext();
export const SelectedLocationUpdateContext = createContext();

export const SelectedServiceContext = createContext();
export const SelectedServiceUpdateContext = createContext();


export const SearchQueryContext = createContext();
export const SearchQueryUpdateContext = createContext();


export const LocationOptionContext = createContext();
export const LocationOptionUpdateContext = createContext();


export const ServiceOptionContext = createContext();
export const ServiceOptionUpdateContext = createContext();


    // USE CONTEXT

export const useSelectedLocation = () => {
    return useContext(SelectedLocationContext)
}
export const useSelectedLocationUpdate = () => {
    return useContext(SelectedLocationUpdateContext)
}


export const useSelectedService = () => {
    return useContext(SelectedServiceContext)
}
export const useSelectedServiceUpdate = () => {
    return useContext(SelectedServiceUpdateContext)
}


export const useSearchQuery = () => {
    return useContext(SearchQueryContext)
}
export const useSearchQueryUpdate = () => {
    return useContext(SearchQueryUpdateContext)
}




export const useLocationOption = () => {
    return useContext(LocationOptionContext)
}
export const useLocationOptionUpdate = () => {
    return useContext(LocationOptionUpdateContext)
}



export const useServiceOption = () => {
    return useContext(ServiceOptionContext)
}
export const useServiceOptionUpdate = () => {
    return useContext(ServiceOptionUpdateContext)
}

    // CONTEXT PROVIDER

export const PreviewAdsFiltersProvider= ({children}) => {

    const [selectedLocation, setSelectedLocation] = useState(() => {
        const stored = window.localStorage.getItem('BACK2BEAUTY_FILTERS_LOCATION');
        return stored ? JSON.parse(stored) : '';
    });
    const [selectedService, setSelectedService] = useState(() => {
        const stored = window.localStorage.getItem('BACK2BEAUTY_FILTERS_SERVICE');
        return stored ? JSON.parse(stored) : '';
    });
    const [searchQuery, setSearchQuery] = useState(() => {
        const stored = window.localStorage.getItem('BACK2BEAUTY_FILTERS_SEARCH');
        return stored ? JSON.parse(stored) : '';
    });
    const [locationOptionValue, setLocationOptionValue] = useState(() => {
        const stored = window.localStorage.getItem('BACK2BEAUTY_FILTERS_LOCATION_OPTION');
        return stored ? JSON.parse(stored) : 'Any Location';
    });
    const [serviceOptionValue, setServiceOptionValue] = useState(() => {
        const stored = window.localStorage.getItem('BACK2BEAUTY_FILTERS_SERVICE_OPTION');
        return stored ? JSON.parse(stored) : 'Any Service';
    });
        

    const SetSelectedLocation = (str) => {
        setSelectedLocation(str)
    }
    const SetSelectedService = (str) => {
        setSelectedService(str)
    }
    const SetSearchQuery = (str) => {
        setSearchQuery(str)
    }
    const SetLocationOptionValue = (str) => {
        setLocationOptionValue(str)
    }
    const SetServiceOptionValue = (str) => {
        setServiceOptionValue(str)
    }

    useEffect(() => {
        window.localStorage.setItem('BACK2BEAUTY_FILTERS_LOCATION', JSON.stringify(selectedLocation))
    }, [selectedLocation])

    useEffect(() => {
        window.localStorage.setItem('BACK2BEAUTY_FILTERS_SERVICE', JSON.stringify(selectedService))
    }, [selectedService])

    useEffect(() => {
        window.localStorage.setItem('BACK2BEAUTY_FILTERS_SEARCH', JSON.stringify(searchQuery))
    }, [searchQuery])

    useEffect(() => {
        window.localStorage.setItem('BACK2BEAUTY_FILTERS_LOCATION_OPTION', JSON.stringify(locationOptionValue))
    }, [locationOptionValue])

    useEffect(() => {
        window.localStorage.setItem('BACK2BEAUTY_FILTERS_SERVICE_OPTION', JSON.stringify(serviceOptionValue))
    }, [serviceOptionValue])

    return (
        <SelectedLocationContext.Provider value={selectedLocation}>
        <SelectedServiceContext.Provider value={selectedService}>
        <SearchQueryContext.Provider value={searchQuery}>
        <LocationOptionContext.Provider value={locationOptionValue}>
        <ServiceOptionContext.Provider value={serviceOptionValue}>
            <SelectedLocationUpdateContext.Provider value={SetSelectedLocation}>
            <SelectedServiceUpdateContext.Provider value={SetSelectedService}>
            <SearchQueryUpdateContext.Provider value={SetSearchQuery}>
            <LocationOptionUpdateContext.Provider value={SetLocationOptionValue}>
            <ServiceOptionUpdateContext.Provider value={SetServiceOptionValue}>
                {children}
            </ServiceOptionUpdateContext.Provider>
            </LocationOptionUpdateContext.Provider>
            </SearchQueryUpdateContext.Provider>
            </SelectedServiceUpdateContext.Provider>
            </SelectedLocationUpdateContext.Provider>
        </ServiceOptionContext.Provider>
        </LocationOptionContext.Provider>
        </SearchQueryContext.Provider>
        </SelectedServiceContext.Provider>
        </SelectedLocationContext.Provider>
    )
}