import './contact-form.styles.scss'


import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Button from '../button/button.component';


const ContactForm= () => {
  const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_czfjmv5', 'template_i41h3jd', form.current, {
            publicKey: 'tTjO4UU9_aSlHhD9Z',
          })
          .then(
            () => {
              alert('SUCCESS')
              console.log('SUCCESS!');
            },
            (error) => {
              alert('Something seems to have gone wrong. Please try again')
              console.log('FAILED...', error.text);
            },
          );
          e.target.reset()
      };
  return (
    <div className="contact-form-section-container">
    <div className="contact-form-container">
        <div className="contact-form-text-container">
            {/* <h2 className="contact-form-subheader"></h2> */}
            <h2 className="contact-form-header">Sign Up form</h2>
        </div>
        <form action="" className="contact-form" ref={form} onSubmit={sendEmail}>
            <label className="contact-form-label">Full Name</label><br/>
                <input className="contact-form-input" type="text"  name={'user_fullname'} required/> <br/>
        <div className="contact-form-label-sml-container">
            <label className="contact-form-label">Phone</label>
            <label className="contact-form-label">Email</label><br/>
        </div>
                <input className="contact-form-input input-sml " type="tel"  name={'user_phone'} />
                <input className="contact-form-input input-sml " type="email"  name={'user_email'} required /><br/>
            <label className="contact-form-label">Address</label><br/>
                <input className="contact-form-input" type="text"  name={'user_adress'} /><br/>
            <label className="contact-form-label">Message</label><br/>
                <textarea className="contact-form-input-msg" name={'user_message'} required ></textarea><br/>
                <Button buttonStyle="btn--outline" type="submit">Submit</Button>
        </form>
    </div>
    </div>
  );
}

export default ContactForm;

