import React,{useState} from 'react'

import "./image-carousel.styles.scss";

import { LuArrowLeft, LuArrowRight, } from "react-icons/lu";


const ImageCarousel = (props) => {
    const {slides} = props
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === slides.length -1 ? 0 :slide + 1);
    }
    const prevSlide = () => {
        setSlide(slide === 0 ? slides.length -1 : slide -1);
    }

    return (
        <div className={slides.length === 1 ? 'round-image-carousel-container' : "image-carousel-container"}>
         <LuArrowLeft className={slides.length === 1 ? 'display-none' :'arrow arrow-left'} onClick={prevSlide}/>
        {slides.map((item,idx) => {
            return( 
            <img src={item.imgSrc} onClick={nextSlide}alt={item.alt} key={idx} className={slides.length === 1 ? 'round-slide' : slide === idx ? "slide " : "slide slide-hidden "}/>
            );
        })}
        <LuArrowRight className={slides.length === 1 ? 'display-none' : 'arrow arrow-right'} onClick={nextSlide} />
        <span className='indicators'>
            {slides.map((_, idx) => {
                return <button key={idx} onClick={() => setSlide(idx)} className={slides.length === 1 ? 'display-none' : slide === idx ? "indicator" : 'indicator indicator-inactive'}></button>
            })}
        </span>
    </div>
  )
}

export default ImageCarousel;
