import AdNavbar from "./components/ad navbar/ad-navbar.component";
import AdvertSection from "./sections/advert section/advert-section.component";
import ReviewSection from "./sections/review section/review-section.component";
import AdFooter from "./components/ad-footer/ad-footer.component";
import { Helmet } from "react-helmet-async";



const AdvertPage = (pageInfo) => {
    return (
        <>
        <Helmet>
            <title>{pageInfo.pageInfo.Name} - {pageInfo.pageInfo.address} | Back2Beauty </title>
            <meta name="description" content={`Book with ${pageInfo.pageInfo.Name} at ${pageInfo.pageInfo.address}`} />
            <link rel="canonical" href={pageInfo.pageInfo.pageHref}/>
        </Helmet>
        <div className="page-background-wrapper">
        <span className="pink-eclipse eclipse-2"/>
        <span className="blue-eclipse eclipse-2"/>
        <span className="purple-eclipse eclipse-2"/>
        <AdNavbar/>
        <AdvertSection pageInfo={pageInfo}/>
        <ReviewSection pageInfo={pageInfo}/>
        <AdFooter/>
        </div>
        </>
    )
}

export default AdvertPage;