import { createContext, useState, useContext, Children } from "react";
import { AdPreviewsInfoList } from "../assests/data/ad preview data/ad-preview-data.component";


    // CREATE CONTEXT

export const PreviewAdsContext = createContext();
export const PreviewAdsUpdateContext = createContext();


    // USE CONTEXT

export const usePreviewAds = () => {
    return useContext(PreviewAdsContext)
}
export const usePreviewAdsUpdate = () => {
    return useContext(PreviewAdsUpdateContext)
}

    // CONTEXT PROVIDER

export const PreviewAdsProvider= ({children}) => {
    const [previewAds, setPreviewAds] = useState(AdPreviewsInfoList)
        
    const FilterPreviewAds =(filteredAds) => {
       setPreviewAds(filteredAds);
    }

    return (
        <PreviewAdsContext.Provider value={previewAds}>
            <PreviewAdsUpdateContext.Provider value={FilterPreviewAds}>
                {children}
            </PreviewAdsUpdateContext.Provider>
        </PreviewAdsContext.Provider>
    )
}