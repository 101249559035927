import ServicePreview from '../../components/service preview/service-preview.component';
import './service-preview-section.styles.scss';
import {Imgs} from '../../assests/data/service-preview-data';

const ServicePreviewSection = () => {
  return (
    <div className='service-preview-section-container'>
      <div className="custom-shape-divider-bottom-1729217942">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
      </div>
        <div className="service-preview-section-text-container">
          <h2 className="sevice-preview-section-header">Advertised services</h2>
          <p className="service-preview-section-text">At Back2Beauty Advertising, we’re actively building our catalog to showcase a comprehensive range of beauty and wellness services. Our goal is to promote every service within the beauty and wellness industry. Here’s a look at the services we currently feature.</p>

        </div>
        <ServicePreview Imgs={Imgs}/>
    </div>
  )
}

export default ServicePreviewSection;
