import ReviewDisplay from '../../components/review display/review-display.component';
import ReviewForm from '../../components/review form/review-form.component';
import './review-section.styles.scss';

const ReviewSection = (pageInfo) => {
  const pageName = pageInfo.pageInfo.pageInfo.Name
  const pageReviews = pageInfo.pageInfo.pageInfo.Reviews

  return (
    <>
    <section id='reviews'>
      <div className='review-section-container'>
          <h2 className='review-section-header'>Reviews</h2>
          <span className='review-section-subheader'>This is not a chat service - you will not receive a response</span>
          <ReviewDisplay pageReviews={pageReviews}/>
          <ReviewForm pageName={pageName}/>
      </div>
    </section>
    </>
  )
}

export default ReviewSection
