import './review-card.styles.scss'
import { FaStar } from 'react-icons/fa'
import reviewIcon1 from '../../assests/review icons/blurry-gradient-haikei.svg'

const ReviewCard = (reviewsArr) => {
    const review = reviewsArr.reviewsArr
    const reviewName = reviewsArr.reviewsArr.reviewName
    const reviewEmail = reviewsArr.reviewsArr.reviewEmail
    const reviewMsg = reviewsArr.reviewsArr.reviewMsg
    const reviewRating = reviewsArr.reviewsArr.reviewRating

  return (
    <div className='review-card-container'>
        <div className="review-card-icon-container">
            <img src={reviewIcon1} alt="reviewIcon1" className='review-card-icon-img'/>
        </div>
        <div className="review-card-text-container">
            <h4 className='review-card-text-headline'>{reviewName}</h4>
            <h6 className="review-card-text-subheadline">{reviewEmail}</h6>
            <p className='review-card-text'>{reviewMsg}</p>
            <div className="review-card-stars-container">
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            </div>
        </div>
    </div>
  )
}

export default ReviewCard
