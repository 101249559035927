import './contact-form-section.styles.scss';
import ContactForm from '../../components/contact form/contact-form.component';

const   ContactFormSection = () => {
    return (
        <>
        <section id="signup">
            <div className="contact-page-container">
                <div className="contact-text-container">
                    <div className="contact-text-headline-container">
                        <span className="contact-text-headline">Sign Up for advertising</span><br/>
                        <span className="contact-text-subheadline">Get in touch with our advertisement sign-up team to start promoting your services <span className='bold'>FOR FREE</span>. </span>
                    </div>
                    <div className="contact-steps-container">
                        <div className="contact-text-instruction-container">
                            <span className="contact-text-instruction-headline">
                                <div className="instruction-number">1</div>Submit a sign up request form.
                            </span>
                            <span className="contact-text-instruction-subheadline">
                                Fill in your contact information then click submit and we will reach out to you with all our advertisment packages.
                            </span>
                        </div>
                        <div className="contact-text-instruction-container">
                            <span className="contact-text-instruction-headline">
                                <div className="instruction-number">2</div> Decide on a package.
                            </span>
                            <span className="contact-text-instruction-subheadline">
                                With the help of our sign up team choose the best package for your advertsing needs <span className="bold">(We do not take any banking details)</span>.
                            </span>
                        </div>
                        <div className="contact-text-instruction-container">
                            <span className="contact-text-instruction-headline">
                                <div className="instruction-number">3</div> Set up your advertisment page.
                            </span>
                            <span className="contact-text-instruction-subheadline">
                                All thats left to do is to fill in a quick and easy form to let us know what you would like to display on your page.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="contact-page-form-container">
                    <div className="contact-page-form">
                        <ContactForm />
                    </div>
                </div>
            </div>
            </section>
            </>
    )
}

export default ContactFormSection;
