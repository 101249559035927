import Button from '../../components/button/button.component';
import './heroSection.styles.scss';

const HeroSection = () => {
  return (
    <>
        <div className='hero-background-wrapper'>
        <span className="pink-eclipse eclipse"/>
        <span className="blue-eclipse eclipse"/>
        <span className="purple-eclipse eclipse"/>
          <div className="hero-section-container">
            <div className="hero-section-text-container">
              <h1 className='hero-section-text-header '>Discover local beauty & wellness services, Cape Town</h1>
              <p className="hero-section-text">
              Discover or advertise services in the beauty and wellness industry with <span className='w400'>BACK2BEAUTY</span>. We connect you with a wide range of service providers in your area, catering to all your beauty and wellness needs.
              </p>
              <div className="cta-container">
                <Button href='#discover'className='btn'>DISCOVER</Button>
                <Button href='#signup'className='btn'>ADVERTISE</Button>
              </div>
            </div>
            <div className="custom-shape-divider-bottom-1729218667">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
          </div>
        </div>
    </>
  )
}

export default HeroSection;
