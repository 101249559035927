// import miniImg1 from '../../mini gallery imgs/WhatsApp Image 2024-06-06 at 17.43.53 (3).jpeg';
// import miniImg2 from '../../mini gallery imgs/WhatsApp Image 2024-06-06 at 17.43.53 (4).jpeg';
// import miniImg3 from '../../mini gallery imgs/WhatsApp Image 2024-06-06 at 17.43.53 (5).jpeg';
// import miniImg4 from '../../mini gallery imgs/WhatsApp Image 2024-07-09 at 11.10.56.jpeg';
// import miniImg5 from '../../mini gallery imgs/WhatsApp Image 2024-07-09 at 11.11.11.jpeg';
// import miniImg6 from '../../mini gallery imgs/WhatsApp Image 2024-07-09 at 11.11.24.jpeg';
// import miniImg7 from '../../mini gallery imgs/WhatsApp Image 2024-07-09 at 11.35.17.jpeg';
// import miniImg8 from '../../mini gallery imgs/WhatsApp Image 2024-07-09 at 11.35.45.jpeg';


import miniImg2 from '../../service preview imgs/Hair stylist service img.jpg';
import miniImg1 from '../../service preview imgs/Beauty Consultant service img.jpg';
// import miniImg5 from '../../service preview imgs/Make Up Artist service img.jpg';
import miniImg3 from '../../service preview imgs/Massage Theripsit service img.jpg';
import miniImg4 from '../../service preview imgs/nail-art-5653458_640.jpg';
import miniImg6 from '../../service preview imgs/Skin Theripists service img.jpg';
import miniImg7 from '../../service preview imgs/pexels-bohlemedia-1884582.jpg';


export const MiniGalleryImgs = [
    {
        imgAlt: miniImg1,
        imgSrc: miniImg1,
    },
    {
        imgAlt: miniImg2,
        imgSrc: miniImg2,
    },
    {
        imgAlt: miniImg3 ,
        imgSrc: miniImg3 ,
    },
    {
        imgAlt: miniImg7,
        imgSrc: miniImg7,
    },
    // {
    //     imgAlt: miniImg5,
    //     imgSrc: miniImg5,
    // },
    {
        imgAlt: miniImg4,
        imgSrc: miniImg4,
    },
    {
        imgAlt: miniImg6,
        imgSrc: miniImg6,
    },
    // {
    //     imgAlt: miniImg8,
    //     imgSrc: miniImg8,
    // },
]
