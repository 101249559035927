import './footer.styles.scss';

import Button from '../button/button.component';

import b2bLogo from '../../assests/b2blogov6.svg';

import InstaIcon from '../../assests/svg icons/insta-icon.svg'
import FaceBookIcon from '../../assests/svg icons/facebook-icon.svg'
import GmailIcon from '../../assests/svg icons/gmail-icon.svg'
import WhatsAppIcon from '../../assests/svg icons/whatsapp-icon.svg'

import { IoIosArrowDown } from "react-icons/io";

const Footer = () => {
  return (
    <section id="footer">
      <div className='footer-container'>
        <div className='top-link-container'>
          <Button onClick= {(e) => window.scrollTo({top: 0, left: 0, behavior: 'smooth',})} ><IoIosArrowDown className='up-icon'/></Button>
        </div>
        <div className="footer-main-container">
          <div className="footer-main-content">
              <ul className="footer-menu-container">
                  <span className='bold'>Menu</span>
                  <li><a href='/'>Home</a></li>
                  <li><a href='#discover'>Discover</a></li>
                  <li><a href='#advertise'>Advertise</a></li>

              </ul>
              <ul className="footer-contact-container">
                  <span className='bold'>Contact</span>
                  <li><img alt='' className='contact-link-icon' src={WhatsAppIcon}/>  +27 75 075 6112</li>
                  <li><img alt='' className='contact-link-icon' src={GmailIcon}/>support@back2beauty.co.za</li>
                  
              </ul>
              <ul className="footer-socials-container">
                  <span className='bold'>Socials</span>
                  <div className="socials-icons-container">
                  <li><a href='https://www.instagram.com/back2beautyadvertising/'><img alt='' className='socials-icon' src={InstaIcon}/></a></li>
                  <li><a href='https://www.facebook.com/people/Back2Beauty-Advertising/61568094058757/'><img  alt='' className='socials-icon' src={FaceBookIcon}/></a></li>  
                  </div>
              </ul>
          </div>
          <div className="footer-main-footer">All Rights Reserved | 
            <a href='https://docs.google.com/document/d/16Nb0f3FXaEewj4tLpGb69sleBysjLYA0/edit?usp=sharing&ouid=111142737094176391116&rtpof=true&sd=true' className='bold white'> Privacy Policy </a>|
            <a href='https://docs.google.com/document/d/1uNfN3YImpAqGYPNNAbiigSpL58RqkVKTEdADUu4Sbx8/edit?usp=sharing' className='bold white' > T&Cs </a>
             | Website by Elevate Online</div>
        </div>
        <div className="footer-secodary-container">
          <img alt='' src={b2bLogo} className="footer-secondary-img"/>
          <p className="footer-secondary-text"><span className='green'>Back2Beauty Advertising</span> your ultimate destination for discovering top notch beauty services and talent!</p>
        </div>
      </div>
    </section>
  )
}

export default Footer
