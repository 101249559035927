import './advert.styles.scss';

import ImageCarousel from '../Image Carousel/image-carousel.component';

const serviceMap = (serviceType, services, idx) => {
  return (
    <div className='advert-sevice-container' key={idx}>
      <h3 className="advert-service-type" >{serviceType}</h3>
      <ul className="advert-service-list-container" >{services.map((service ) => <li className="advert-service" key={service}>{service}</li>)}</ul>
    </div>
  )
}



const Advert = (data) => {

  const pageData = data.data.pageInfo

  const imgSlides = pageData.imgSlides;
  const name = pageData.Name;
  const address = pageData.address;
  const servicesInfo = pageData.servicesInfo;

  return (
    <div className='advert-containerx' >
      <div className="advert-image-container">
      <ImageCarousel slides={imgSlides}/>
      </div>
      <h1 className="advert-header">{name}</h1>
      <h3 className="advert-subheader">{address}</h3>
      
      {/* SERVICES SECTION */}

      <h2 className='advert-services-header'>SERVICES</h2>
      {
        servicesInfo.map((item, idx)=>{
          return (
            serviceMap(item.serviceType, item.services, idx)
          )
            
        })
      }   
    </div>
  )
}

export default Advert;
