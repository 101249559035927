import './navbar.styles.scss';

import { Outlet,Link} from 'react-router-dom';
import { useState } from 'react';

import { FaBars, FaTimes } from 'react-icons/fa';

import b2bLogo from '../../assests/b2blogov6.svg';

import Button from '../../components/button/button.component';


const Navbar = ( ) => {


    const [mobileNavState, setMobileNavState] = useState(false);

    const enableMobileNav = () => {
        setMobileNavState(true)
    }

    const disableMobileNav = () => {
        setMobileNavState(false)
    }

    return(
        <>
            <div className="nav-section-container">
                <div className='nav-container'  >
                    <div className="logo-container">
                        <Link to={'/'} >
                            <img alt='' src={b2bLogo} className='b2b-logo'/>
                        </Link>
                    </div>
                    <div className='links-container'>
                    <nav className={mobileNavState ? "responsive_nav navbar" : "navbar"}>
                            <a
                                className='nav-link' 
                                onClick={disableMobileNav}
                                href='/'

                            >
                                Home
                            </a>
                            <a
                                className='nav-link'
                                onClick={disableMobileNav} 
                                href="#discover"

                            >
                                Discover
                            </a>
                            <a
                                className='nav-link'
                                onClick={disableMobileNav} 
                                href="#signup"
                            >
                                Advertise
                            </a>
                            <Button
                                onClick={disableMobileNav} 
                                href='#footer'
                                >
                                
                                Contact Us
                            </Button>


                            <button className='nav-btn nav-close-btn' onClick={disableMobileNav}>
                                <FaTimes/>
                            </button>
                    </nav>
                            <button className='nav-btn ' onClick={enableMobileNav}>
                                <FaBars/>
                            </button>
                    </div>
                </div>
            </div>
        <Outlet/>
        </>
    );
}

export default Navbar;
